import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import './registerServiceWorker'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import Notifications from '@kyvg/vue3-notification'

import "bootstrap/dist/css/bootstrap.min.css"

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import { VueReCaptcha } from 'vue-recaptcha-v3'

const VueTelInputOptions = {
    mode: "international",
    onlyCountries: ["SG"],
    dropdownOptions: {
        disabled: true,
        showDialCodeInList: false
    },
    defaultCountry: "SG",
    inputOptions: {
        autocomplete: 'off'
    }
}

createApp(App).use(store).use(router).use(Vuex)
    .use(VueSweetalert2)
    .use(VueTelInput, VueTelInputOptions)
    .use(DatePicker)
    .use(Notifications)
    .use(VueReCaptcha, { 
        siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
        loaderOptions: {
            autoHideBadge: true
        }
    })
    .mount('#app')

import "bootstrap/dist/js/bootstrap.js"
