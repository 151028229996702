import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '',
    name: '',
    component: () => import('../views/MainView')
  },
  {
    path: '/landing',
    name: 'Home',
    component: () => import('../views/LandingPage')
  },
  {
    path: '/user-signup/:id',
    name: 'User Signup',
    component: () => import('../views/HomeView')
  },
  {
    path: '/sign_up',
    name: 'Sign Up',
    component: () => import('../views/HomeView'),
    meta: {
      forceLogOut: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/HomeView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/logmein/:token',
    name: 'Token Login',
    component: () => import('../views/HomeView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/HomeView'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/reset_password/:code?',
    name: 'Reset Password',
    component: () => import('../views/HomeView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/webview/close',
    name: 'WebView',
    component: () => import('../views/HomeView'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/external/sign_up',
    name: 'External Sign Up',
    component: () => import('../views/ExternalView'),
    meta: {
      forceLogOut: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: () => import('../views/TermsView'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to)
  //console.log(from)
  if(to.meta.disableIfLoggedIn) {
    if (sessionStorage.getItem("ovvy2_userEncryptId")) {
      next({name: 'Profile'});
    }else {
      // next();
    }
  }else if(to.meta.shouldBeLoggedIn) {
    if (sessionStorage.getItem("ovvy2_userEncryptId")) {
      // next();
    }else {
      next({name: 'Login'});
    }
  }else if(to.meta.forceLogOut) {
    sessionStorage.removeItem("ovvy2_userEncryptId");
    next();
  }
  next();
});

export default router
