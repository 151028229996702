<template>
  <router-view />
  <BaseFooter />
  <notifications />
</template>

<script>
  import BaseFooter from '@/components/common/BaseFooter'

  export default {
    name: 'App',
    components: {
      BaseFooter
    },
    data() {
      return {
        service_url: process.env.VUE_APP_SERVICES_URL
      }
    },
    computed: {
      isInIframe() {
        // return true;
        return window.location !== window.parent.location
      },
      user_id_cookie() {
        return process.env.VUE_APP_COOKIE_ID;
      },
      user_detail_cookie() {
        return process.env.VUE_APP_COOKIE_DETAILS;
      }
    },
    methods: {
      async recaptcha(action) {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "signup".
        const token = await this.$recaptcha(action)

        // Do stuff with the received token.
        //   console.log(token)
        return token
      },
      setCookie(cname, cvalue, exHours) {
        const d = new Date();
        d.setTime(d.getTime() + (exHours*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=ovvyapp.com";
      },
      getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    }
  }
</script>
<style>
@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
</style>
